<template>
  <div :class="component_key === 'dash_widget' ? 'dash' : ''">
    <b-row>
      <b-col md="12" sm="12">
        <b-form-group
          class="select-multi"
          :label="`${label} ${is_required || required ? '*' : ''}`"
        >
          <v-select
            v-model="selectedValue"
            :clearable="is_required || required ? false : true"
            label="label"
            :options="optionsData"
            :reduce="(option) => option.value"
            :disabled="isEditable || loading || isGlobalFilter"
            :placeholder="`Select ${label}`"
            @input="inputChanged"
            :loading="loading"
            :multiple="is_multiple"
          >
            <template #selected-option="{ label }">
              <div class="selected-option-multi">
                <span> {{ label }}</span>
              </div>
            </template></v-select
          >
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormCheckbox, BFormGroup, BRow, BCol, BSpinner } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import APIService from "@/libs/api/api";
export default {
  props: [
    "name",
    "desc",
    "label",
    "type",
    "component",
    "component_value",
    "value",
    "options",
    "isEditable",
    "is_required",
    "is_multiple",
    "required",
    "lookup",
    "name",
    "id",
    "account_id",
    "component_key",
    "isGlobalFilter",
    "is_multiple_unit"
  ],
  components: { BFormCheckbox, vSelect, BFormGroup, BRow, BCol, BSpinner },
  data() {
    return {
      isChecked: false,
      isIndeterminate: false,
      selectedValue: null,
      loading: false,
      optionsData: [],
      isAPICall: false,
      filterVal: "",
      timeoutId: ""
    };
  },
  mounted() {
    this.isChecked = this.value;
    this.is_multiple = this.lookup.multiple || this.is_multiple_unit;
    this.getData();

    if (this.value) {
      this.selectedValue = this.value;
    } else {
      if (this.options) {
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].default) {
            this.selectedValue = this.options[i].value;
            break;
          }
        }
      }
    }
  },
  watch: {
    id(val) {
      this.selectedValue = "";
      this.optionsData = [];
      this.$emit("input", this.selectedValue);
      this.getData();
    },
    account_id(val) {
      this.selectedValue = "";
      this.optionsData = [];
      this.getData();
      this.$emit("input", this.selectedValue);
    }
  },
  methods: {
    inputChanged() {
      this.$emit("input", this.selectedValue);
    },
    async getData() {
      try {
        if (
          this.lookup &&
          this.lookup.enable &&
          this.lookup.service &&
          this.lookup.method
        ) {
          const obj = {};
          if (this.is_multiple_unit || this.id) {
            obj.unit_id = this.id;
          }
          if (this.name) {
            obj.name = this.name;
          }
          this.loading = true;
          const data = await new APIService().api(
            { service: this.lookup.service, method: this.lookup.method },
            {
              account_id: this.account_id,
              filters: this.lookup.filters,
              ...obj
            },
            {
              options: {
                show_loader: true,
                http_headers: {},
                api_version: undefined
              }
            }
          );
          this.is_multiple = this.lookup.multiple || this.is_multiple_unit;
          this.loading = false;
          if (data && data.data && data.data.options) {
            this.optionsData = JSON.parse(JSON.stringify(data.data.options));
            if (
              data.data.options &&
              data.data.options.length &&
              data.data.options.length === 1
            ) {
              this.selectedValue = this.optionsData[0].value;
              this.$emit("input", this.selectedValue);
              this.$emit("emitDataToParent", null);
            }
          } else if (data && data.error && data.error.message) {
            this.$emit("input", "");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "",
                text: data.error.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
        } else {
          if (
            this.component_value &&
            this.component_value.length &&
            this.component_value[0] &&
            this.component_value[0].isApiCall
          ) {
            this.isAPICall = true;
            await this.getDataLoad(this.component_value[0]);
          } else {
            this.optionsData = this.options;
          }
        }
      } catch (err) {
        this.isShow = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getDataLoad(componentValue) {
      try {
        this.loading = true;
        let filter = {};
        if (
          componentValue.filters &&
          componentValue.filters.length &&
          componentValue.filters[0] &&
          componentValue.filters[0].field &&
          this.filterVal
        ) {
          componentValue.filters[0].value = this.filterVal;
          filter.filters = componentValue.filters;
        }
        const data = await new APIService().api(
          {
            service: componentValue.service,
            method: componentValue.method
          },
          {
            ...filter,
            page: componentValue.page || 1,
            page_size: componentValue.page_size || 100,
            account_id: componentValue.isAccount
              ? localStorage.getItem("USER_ACCOUNT_ID")
              : null,
            id: this.value ? this.value : null
          },
          {
            options: {
              show_loader: true,
              http_headers: {},
              api_version: undefined
            }
          }
        );
        this.isAPICall = true;
        this.loading = false;
        if (data && data.data && data.data[componentValue.main]) {
          const list = JSON.parse(
            JSON.stringify(data.data[componentValue.main])
          ).map((e) => {
            if (componentValue.value) {
              e.value = e[componentValue.value];
            }
            if (componentValue.title) {
              e.text = e[componentValue.title];
            }
            return e;
          });
          this.optionsData = JSON.parse(JSON.stringify(list));
          if (
            data.data[componentValue.main] &&
            data.data[componentValue.main].length &&
            data.data[componentValue.main].length === 1
          ) {
            this.selectedValue = this.optionsData[0].value;
            this.$emit("input", this.selectedValue);
            this.$emit("emitDataToParent", null);
          }
        } else if (data && data.error && data.error.message) {
          this.$emit("input", "");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "",
              text: data.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.isShow = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async searchFilter(e) {
      if (this.isAPICall && e) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(async () => {
          this.filterVal = e;
          await this.getDataLoad(this.component_value[0]);
        }, 400);
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.dark-layout {
  .dash {
    [dir="ltr"] .vs__spinner {
      border-right: 0.9em solid rgb(255 238 238 / 98%) !important;
      border-left: 0.9em solid rgb(234 237 231) !important;
    }
    [dir] .vs__spinner {
      border-top: 0.9em solid rgb(255 251 251) !important;
    }
  }
}
.select-multi .v-select .vs__selected {
  width: auto !important;
}
.select-multi .selected-option-multi {
  max-width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
